$primary-color: #1772b9;
$primary-color-darker: #0e4772;
$gray: #808080;
$light-gray: #a9a9a9;

.orange {
  color: $primary-color;
}

.gray {
  color: $gray;
}

.light-gray {
  color: $light-gray;
}

.leftNav {
  background-color: $primary-color;
  color: white;
}

// #wrapper {
//   overflow-x: hidden;
// }

// #sidebar-wrapper {
//   min-height: 100vh;
//   margin-left: -15rem;
//   -webkit-transition: margin 0.25s ease-out;
//   -moz-transition: margin 0.25s ease-out;
//   -o-transition: margin 0.25s ease-out;
//   transition: margin 0.25s ease-out;
// }

// #sidebar-wrapper .sidebar-heading {
//   padding: 0.875rem 1.25rem;
//   font-size: 1.2rem;
// }

// #sidebar-wrapper .list-group {
//   width: 15rem;
// }

.main-content {
  min-width: 100vw;
  // same as leftnav width
  // padding-left: 48px;
  // padding-bottom: 10em;

  // bs = xs
  @media (max-width: 575px) {
    padding-left: 0;
  }
}

@media (min-width: 768px) {
  #sidebar-wrapper {
    margin-left: 0;
  }

  .main-content {
    min-width: 0;
    width: 100%;
  }

  #wrapper.toggled #sidebar-wrapper {
    margin-left: -15rem;
  }
}

.btn-outline-orange {
  color: $primary-color;
  border-color: $primary-color;
}

.btn-outline-gray {
  color: $light-gray;
  border-color: $light-gray;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: $primary-color;
  border-color: $primary-color;
}

.btn-outline-gray:hover {
  color: #fff;
  background-color: $light-gray;
  border-color: $light-gray;
}

.fs-3 {
  font-size: 1.3em;
}

h1 {
  // font-size: 3.5rem;
}

.bg-theme {
  background-color: $primary-color;
}

.main-btn {
  background-color: $primary-color;
  padding: 0.2em 1.4em !important;
  color: white;
  border: none;
}

.main-btn:hover {
  background-color: #85351c !important;
}

.faq-item {
  padding-bottom: 0.3rem;
  border-top: 1px solid #e8e8e8;
  border-right: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
}

.left-auto {
  left: auto;
}
.right-auto {
  right: auto;
}

.max-width-40 {
  max-width: 40%;
}

.max-width-25 {
  max-width: 25%;
}

.max-width-50 {
  max-width: 50%;
}

button:disabled {
  background-color: #8080805e !important;
}

// medium
figure > img {
  max-width: 100%;
}

// home ---------------------------------------
.home-first-section-bg {
  // display: block;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background: url('../wego/bg.webp') no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-position: center;
  // height: 30px; /* Height of new image */
  padding-bottom: 12vh;
  padding-top: 20vh;
}

.home-first-section {
  color: white;
  background-color: black;

  .logo {
    max-width: 55%;
    // margin-top: 20vh;
    // @media (max-width: 575px) {
    //   margin-top: 12vh;
    // }
  }
}

.home-wrapper {
  margin-top: -10vh;

  .roadmap {
    padding-top: 2rem;
    padding-bottom: 2rem;
    background-color: rgb(29, 29, 29);
    color: white;
    tbody,
    td,
    tfoot,
    th,
    thead,
    tr {
      border-color: #464646;
    }
    th {
      color: $primary-color;
    }
    td {
      color: white;
    }
  }
}

.home-wrapper .goal {
  font-size: 1.3rem;
  color: $primary-color;
  font-weight: bold;
}

.bg-gradient-1 {
  background: rgb(7, 97, 115);
  background: linear-gradient(
    90deg,
    rgba(7, 97, 115, 1) 0%,
    rgba(56, 56, 176, 1) 35%,
    rgba(7, 97, 115, 1) 100%
  );
}

.border-none {
  border: none;
}

.slider-img {
  max-width: 100%;
}

.common-sub-p {
  font-size: 1.2rem;
}

// mint --------------------------------------

.mint-container {
  padding-top: 20vh;
  padding-bottom: 20vh;
  background: url('../wego/bg.webp') no-repeat;
  background-size: cover;
  background-position: center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  @media (max-width: 575px) {
    padding-top: 12vh;
    padding-bottom: 12vh;
  }
}
