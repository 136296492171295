/**
--------------
Top Menu Navigation
--------------
**/

.justify-content-end {
  justify-content: flex-end;
}

.navbar-expand-lg {
  justify-content: space-between;
}

.navbar {
  background-color: #0f0f0f;
  // height: 10vh !important;
  // padding-top: 25px;
  .social {
    width: 30%;
    align-items: center;
    @media (max-width: 575px) {
      width: 70%;
    }

    .img-fluid {
      height: 20px;
    }
  }
}

/**
--------------
Left Navigation
--------------
**/

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  // padding: 10vh 0 0;
}

.sidebar-sticky {
  position: sticky;
  top: 0;
  // height: calc(100vh - 10vh);
  padding-top: 0.5rem;
  overflow-x: hidden;
  overflow-y: auto;
}

.leftNav {
  // width: 6em;
  width: 48px;
  white-space: nowrap;
  transition: width 0.5s;
  position: absolute;
  z-index: 1;
  height: 100%;

  // box-shadow: 5px 0 1px -3px #eee;
  .menu {
    display: none;
  }

  ul {
    padding: 0;
    list-style-type: none;
    text-align: left;
  }

  // li = .item-menu
  li {
    transition: 0.5s;
    width: auto;
    height: auto;
    padding-left: 15px;
    color: white;
  }

  a {
    text-decoration: none;
    line-height: 50px;
  }

  .navbrand-img {
    padding-left: 0;
  }

  .navbrand-img img {
    width: 100%;
    padding: 15px;
  }

  // mobile
  // @media (max-width: 575px) {
  //   width: 100%;

  //   .menu {
  //     display: inline;
  //   }

  //   li {
  //     margin-bottom: 1em;
  //   }
  // }
}

.leftNav:hover {
  width: 180px;

  .menu {
    display: inline;
  }

  li {
    margin-bottom: 1em;
  }

  // @media (max-width: 575px) {
  //   width: 100%;

  //   .menu {
  //     display: inline;
  //   }

  //   li {
  //     margin-bottom: 1em;
  //   }
  // }
}

.leftNav,
.leftNav:hover {
  @media (max-width: 575px) {
    width: 100%;

    .navbrand-img img {
      width: 100%;
    }

    .menu {
      display: inline;
    }

    li {
      margin-bottom: 1em;
    }
  }
}

.offcanvas-start {
  @media (max-width: 575px) {
    width: 70vw;
  }
}
